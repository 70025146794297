import React from "react";
import "./Thankyou.css";
import { Link } from "react-router-dom";
import thankyou from '../image/thankyou.svg'

function Thankyou() {
  return (
    <>
   <div className="thankyou parent">
    <div className="thankyou-cont cont">
    <img src={thankyou} alt="thank you"  />
      <p>
        {" "}
        <span>Thank you</span> for contacting us we will get back to you soon
      </p>

      <Link to="/" className="btn">
        Back to main site
      </Link>
    </div>
   </div>
    </>
  );
}

export default Thankyou;
