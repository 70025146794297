import React from "react";
import "./Footer.css";
import { IoCallSharp } from "react-icons/io5";
function Footer() {
  return (
    <>
      <div className="footer parent">
        <div className="cont footer-cont">
          <div className="logo-cont">
             <div className="logo-footer"></div>
             <div className="button_div">
              <a href="tel:+917263802727" className='btn'><span><IoCallSharp /></span> 7263802727 </a>
              </div>
             </div>
          <p>Copyright 2023 The Aligner Dentist | Designed and developed by <a href="https://diwise.uk" target="_blank" >DIwise</a>.</p>
        </div>
      </div>
    </>
  );
}

export default Footer;
