import React, { useEffect, useState } from "react";
import "./Contact.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
function Contact() {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [contact, setcontact] = useState("");
  const [message, setmessage] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  useEffect(() => {
    setDate(new Date().toLocaleDateString());
    setTime(new Date().toLocaleTimeString());
  });
  const navigate = useNavigate();
  const submitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("Name", name);
    formData.append("Email", email);
    formData.append("Contact", contact);
    formData.append("Message", message);
    formData.append("Date", date);
    formData.append("Time", time);
    if (name && email && contact && message) {
      fetch(
        "https://script.google.com/macros/s/AKfycbxay_GVFD5g-UOlFlGAjg6lYxxZ3Rdz1sAQi52v9R6WFkUl0zO066RcGTiwmxB9pVVdaw/exec",
        {
          method: "POST",
          body: formData,
        }
      )
        .then((res) => {
          navigate("/thankyou");
        })
        .catch((error) => {});
    } else {
      toast.error("Each Fields are required", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: false,
        theme: "light",
        className: "custom-toast",
      });
    }
  };
  return (
    <div className="parent contact">
      <div className="contact-cont cont">
        <div className="form_wrapper">
          <h2>Contact <span>Us</span></h2>
          <form action="#" onSubmit={submitHandler}>
            <input
              type="text"
              required
              placeholder="Full Name"
              value={name}
              onChange={(e) => setname(e.target.value)}
            />
            <input
              type="email"
              required
              value={email}
              placeholder="Email"
              onChange={(e) => setemail(e.target.value)}
            />
            <input
              type="number"
              required
              value={contact}
              placeholder="Contact"
              onChange={(e) => setcontact(e.target.value)}
            />
            <textarea
              type="text"
              cols="30"
              rows="5" 
              required
              value={message}
              placeholder="Message"
              onChange={(e) => setmessage(e.target.value)}
            />
            <input type="submit" className="btn" />
          </form>
          <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
            theme="light"
            className="toast-wrapper"
          />
        </div>
      </div>
    </div>
  );
}

export default Contact;
