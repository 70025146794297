import React, { useState } from 'react'
import "./accordian.css"
import { IoIosArrowUp } from "react-icons/io";
const Accordian = () => {
    const [toggle, settoggle] = useState(false)
    const [toggle2, settoggle2] = useState(false)
    const [toggle3, settoggle3] = useState(false)
    const [toggle4, settoggle4] = useState(false)
    const [toggle5, settoggle5] = useState(false)
    return (
        <div className='accordian_container'>
            <div className="accor1 accor">
                <div className="accorhead" onClick={() => settoggle(!toggle)}>
                    <h3>What are aligners?</h3>
                    <div className={toggle ? 'svg2':'svg'}><IoIosArrowUp /></div>
                </div>
                <p className={toggle ? 'accorbottom2' : "accorbottom"}>
                    Aligners are Orthodontic devices that are a transparent, plastic form of dental braces used to adjust teeth to make your smile more beautiful!
                </p>
            </div>
            <div className="accor1 accor">
                <div className="accorhead" onClick={() => settoggle2(!toggle2)}>
                    <h3>How do the aligners work?</h3>
                    <div className={toggle2 ? 'svg2':'svg'}><IoIosArrowUp /></div>
                </div>
                <p className={toggle2 ? 'accorbottom2' : "accorbottom"}>
                    The treatment starts with a consultation. Your doctor evaluates your smile and then maps out a precise, customised digital treatment plan for transformation of your smile. Once you approve your plan, your unique aligners will be created, and instructions provided by your dentist. Follow your plan to a brand new smile.
                </p>
            </div>
            <div className="accor1 accor">
                <div className="accorhead" onClick={() => settoggle3(!toggle3)}>
                    <h3>What are braces?</h3>
                    <div className={toggle3 ? 'svg2':'svg'}><IoIosArrowUp /></div>
                </div>
                <p className={toggle3 ? 'accorbottom2' : "accorbottom"}>
                    Braces are orthodontic devices consisting of brackets, wires, and bands that work together to straighten and align teeth. They are generally made up of clearly visible metal or ceramics.
                </p>
            </div>
            <div className="accor1 accor">
                <div className="accorhead" onClick={() => settoggle4(!toggle4)}>
                    <h3>How does braces work?</h3>
                    <div className={toggle4 ? 'svg2':'svg'}><IoIosArrowUp /></div>
                </div>
                <p className={toggle4 ? 'accorbottom2' : "accorbottom"}>
                    Braces work by applying continuous, gentle pressure on teeth through the archwire, guiding them into the desired position over time.
                </p>
            </div>
            <div className="accor1 accor">
                <div className="accorhead" onClick={() => settoggle5(!toggle5)}>
                    <h3>Can I remove the aligners for special occasions?</h3>
                    <div className={toggle5 ? 'svg2':'svg'}><IoIosArrowUp /></div>
                </div>
                <p className={toggle5 ? 'accorbottom2' : "accorbottom"}>
                    Yes, one of the advantages of aligners is its removability. You can take them off for meals, important events, or whenever necessary. However, for the best results, it's recommended to wear them for at least 20-22 hours per day.
                </p>
            </div>
        </div>
       
    )
}

export default Accordian