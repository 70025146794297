import React from 'react';
import "./toolbox.css";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaMapMarkedAlt } from "react-icons/fa";

const Toolbox = () => {
  const handleWhatsappClick = () => {
    const phoneNumber = '+917263802727';
    const message = 'Hi, I want to book an appointment. Please let me know the details!';
    const whatsappLink = `https://wa.me/${phoneNumber}/?text=${encodeURIComponent(message)}`;
    window.open(whatsappLink, '_blank');
  };

  const mapClick = () => {
    const mapLink = "https://www.google.com/maps/dir//Office+no-121,+The+Team+Dental+%7C+Dentist+in+Viman+Nagar,+10+Biz+Park,+Symbiosis+Law+School+Rd,+Mhada+Colony,+Viman+Nagar,+Pune,+Maharashtra+411014/@18.5751983,73.9074574,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bc2c135d7259413:0x943d1ac86ccd2829!2m2!1d73.9100323!2d18.5751983?entry=ttu";
    window.open(mapLink, '_blank');
  }

  return (
    <div className='toolbox_wrapper'>
      <button className='whatsapp toolbtn' onClick={handleWhatsappClick}><IoLogoWhatsapp /></button>
      <button onClick={mapClick} className='map toolbtn'><FaMapMarkedAlt /></button>
    </div>
  );
}

export default Toolbox;
