import React, { useEffect, useState } from "react";
import "./Home.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { Tilt } from "react-tilt";
import before from "../image/Before.jpg";
import after from "../image/After.jpg";
import ImageSlider from "react-image-comparison-slider";

//images

import icon5 from "../image/assesment.webp";
import icon6 from "../image/appointment.webp";
import icon7 from "../image/smile.webp";
import SwiperComponent from "../comp/SwiperComponent";
import Accordian from "../comp/Accordian";
function Home() {
  useEffect(() => {
    AOS.init();
  }, []);

  const defaultOptions = {
    reverse: false, // reverse the tilt direction
    max: 35, // max tilt rotation (degrees)
    perspective: 1000, // Transform perspective, the lower the more extreme the tilt gets.
    scale: 1, // 2 = 200%, 1.5 = 150%, etc..
    speed: 1000, // Speed of the enter/exit transition
    transition: true, // Set a transition on enter/exit.
    axis: null, // What axis should be disabled. Can be X or Y.
    reset: true, // If the tilt effect has to be reset on exit.
    easing: "cubic-bezier(.03,.98,.52,.99)", // Easing on enter/exit.
  };

  return (
    <>
      <div className="swiper_wrapper" data-aos="fade-left">
        <SwiperComponent />
      </div>
      <div className="parent steps">
        <div className="cont steps-cont">
          <h2 data-aos="fade-down" data-aos-duration="800">
            <span> 3-STEP</span> SMILE MAKEOVER
          </h2>

          <p data-aos="fade-down" data-aos-duration="500">
            Getting A New Smile Isn’t That Hard.
          </p>
          <div className="gap"></div>
          <div className="gap"></div>
          <div className="gap"></div>
          <div className="gap"></div>
          <div className="gap"></div>
          <div className="gap"></div>

          <div className="steps-cards">
            <div className="card" data-aos="fade-up">
              <div className="icon">
                <img src={icon5} alt="assessment" />
              </div>
              <h3>Get The Assessment</h3>
              <p>
                Teeth scans to take a sneak peek at what your smile could look
                like with straighter teeth.
              </p>
            </div>

            <div className="card" data-aos="fade-up" data-aos-duration="500">
              <div className="icon">
                <img src={icon6} alt="appointments" />
              </div>
              <h3>Set Up Your Appointments</h3>
              <p>
                Appointments with your doctor to ensure your aligners fit well
                and let you know what to expect.
              </p>
            </div>
            <div className="card" data-aos="fade-up" data-aos-duration="1000">
              <div className="icon">
                <img src={icon7} alt="smile it out" />
              </div>
              <h3>Go Smile It Out</h3>
              <p>
                Keep your transformed smile retained with periodic scans and
                appointments with the dentist.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="about parent">
        <h2 data-aos="fade-down" data-aos-duration="800">
          HEAR FROM YOUR <span>DENTIST</span>{" "}
        </h2>

        <div className="cont about-doc-cont">
          <div className="left">
            <div className="gap"></div>
            <p data-aos="fade-down" data-aos-duration="500">
              Greetings! I'm Dr. Aniket A Jogdand, your Consultant Orthodontist.
              With expertise in various braces, aligners, and specialised
              appliances, including Invisalign & Flash. Committed to creating
              confident smiles, I'm here to guide you on your journey to optimal
              oral health. Let's achieve that radiant smile together!
            </p>
          </div>
          <div className="right">
            <Tilt options={defaultOptions}>
              <div className="img"></div>
            </Tilt>
          </div>
        </div>
        {/* content 2 */}
        <div className="cont about-cont">
          <div className="left">
            <div className="gap"></div>
            <p data-aos="fade-down" data-aos-duration="500">
              At Aligner Dentist, I Dr. samidha patil certified “Essentials by
              Invisalign provider” am dedicated to provide you with the latest
              advancements in dental care, and delighted to announce our
              collaboration with renowned aligner companies to enhance your
              orthodontic experience. In our commitment to delivering the
              highest standard of care, we have partnered with leading aligner
              companies to bring you state-of-the-art teeth straightening
              options.
            </p>
          </div>
          <div className="right">
            <Tilt options={defaultOptions}>
              <div className="img"></div>
            </Tilt>
          </div>
        </div>
      </div>

      <div className="partners parent">
        <div className="cont partners-cont">
          <h2 data-aos="fade-down" data-aos-duration="300">
            The Aligners Dentist In <span>Association With</span>{" "}
          </h2>
          <div className="gap"></div>
          <div
            className="partner-brands"
            data-aos="fade-up"
            data-aos-duration="300"
          >
            <div className="brand"></div>
            <div className="brand"></div>
            <div className="brand"></div>
          </div>
          <p data-aos="fade-up" data-aos-duration="1000">
            Our Inhouse Teeth Scanner and OPG Partner “Smile View”{" "}
          </p>
        </div>
      </div>

      <div className="table parent">
        <div className="table-cont cont">
          <h2 data-aos="fade-down" data-aos-duration="300">
            Weigh your choices <span> Braces or Aligners? </span>{" "}
          </h2>
          <div className="gap"></div>
          <div className="gap"></div>
          <div className="gap"></div>
          <div className="gap"></div>
          <div className="gap"></div>
          <table data-aos="fade-up" data-aos-duration="500">
            <tr>
              <th>Features</th>
              <th>Braces</th>
              <th>Aligners</th>
            </tr>
            <tr>
              <td>Visibility</td>
              <td>Clearly Visible</td>
              <td>Nearly Invisible</td>
            </tr>
            <tr>
              <td>Removabilty</td>
              <td>Non-Removable</td>
              <td>Removable</td>
            </tr>
            <tr>
              <td>Oral Hygiene</td>
              <td> Challenging</td>
              <td>No effect</td>
            </tr>
            <tr>
              <td>Eating Habits </td>
              <td>Restricted diet</td>
              <td>No restrictions</td>
            </tr>
            <tr>
              <td>Comfort </td>
              <td>Initial Discomfort</td>
              <td>Fairly Comfortable</td>
            </tr>
            <tr>
              <td>Suitability </td>
              <td>Wide range of dental issues</td>
              <td>For mild to moderate cases</td>
            </tr>
            <tr>
              <td>Cost </td>
              <td>Generally less expensive</td>
              <td>Relatively more expensive</td>
            </tr>
          </table>
        </div>
      </div>

      <div className="before-after parent">
        <div className="before-after-cont cont">
          <h2 data-aos="fade-down" data-aos-duration="200">
            Whatever your needs,
            <br />
            <span>we will take care of you and your smile.</span>
          </h2>
          <div className="gap"></div>
          <div className="gap"></div>
          <div className="gap"></div>
          <div className="ba" data-aos="fade-up" data-aos-duration="500">
            <ImageSlider
              image1={after}
              image2={before}
              sliderWidth={2}
              sliderColor="white"
              handleColor="white"
              handleBackgroundColor="grey"
            />
          </div>
        </div>
      </div>

      <div className="parent s2s">
        <h2>
          Straighten Them Out Into A New, <span>Confident Smile.</span>
        </h2>
        <div className="s2s-cont cont">
          <div
            className="inner_cont"
            data-aos="fade-up"
            data-aos-duration="500"
          >
            <p>
              A complete smile assessment for that first step towards your new
              smile.
            </p>
            <a
              href="https://providerbio-apac.invisalign.com/sv/1617845#start"
              className="conf-btn"
            >
              Scan Your Smile
            </a>
          </div>
          <div
            className="inner_cont"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <p> It’s your time to have a brand new smile, register here! </p>
            <Link className="conf-btn" to="/contact">
              Book Your Appointment
            </Link>
          </div>
        </div>
      </div>

      <div className="faq parent">
        <h2 className="h2" data-aos="fade-down" data-aos-duration="300">
          {" "}
          <span>Faqs</span>{" "}
        </h2>
        <div
          className="cont faq-cont"
          data-aos="fade-up"
          data-aos-duration="500"
        >
          <Accordian />
        </div>
      </div>
      <div className="parent last">
        <div className="last-cont cont">
          <h2 data-aos="fade-down" data-aos-duration="300">
            Now it's your turn!
          </h2>
          <Link
            className="btn"
            to="/contact"
            data-aos="fade-up"
            data-aos-duration="500"
          >
            Book Appointment
          </Link>
        </div>
      </div>

      <div className="buttons parent">
        <div className="btn-cont ">
          <Link to="/contact" className="enquiry-now ">Enquiry Now</Link>
          <Link to="tel:+917263802727" className="call-now ">Call Now</Link>
        </div>
      </div>
    </>
  );
}

export default Home;