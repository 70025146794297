import React from 'react'
import "./swiper.css"
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import {Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css/pagination';
import icon1 from '../image/innovation.webp'
import icon2 from '../image/age_group.webp'
import icon3 from '../image/lifestyle.webp'
import logo from '../image/logo1.png'
import { IoCallSharp } from "react-icons/io5";
const SwiperComponent = () => {
    return (
        <div className='swiper_container'>

            <Swiper
                slidesPerView={1}
                spaceBetween={0}
                centeredSlides={true}
                autoplay={{
                    delay: 2500,
                    pauseOnMouseEnter: true,
                  }}
                pagination={{
                    dynamicBullets: true,
                    clickable: true,
                }}
                modules={[Autoplay,Pagination]}
                className="mySwiper"
            >
                <SwiperSlide className='swiper_slide  swiper_slide1'>
                    <div className="bg1"></div>
                    <div className="content_box content_box1">
                        <div className="logo_div">
                            <img src={logo} alt="reason to smile" className='mylogo' />
                        </div>
                        <div className="button_div">
                            <a href="tel:+917263802727" className='btn'><span><IoCallSharp /></span> 7263802727 </a>
                        </div>
                        <h1>DON’T NEED A REASON TO SMILE</h1>
                        <p>With state-of-art aligners brought to you by The Aligner Dentist</p>
                    </div>

                </SwiperSlide>
                <SwiperSlide className='swiper_slide swiper_slide2'>
                    <div className="bg2"></div>
                    <div className="content_box content_box2">
                       <div className="logo_div">
                            <img src={logo} alt=" smile into laughters" className='mylogo' />
                        </div>
                        <div className="button_div">
                            <a href="tel:+917263802727" className='btn'><span><IoCallSharp /></span> 7263802727 </a>
                        </div>
                        <h1>BURST SMILES INTO LAUGHTERS</h1>
                        <p>Team Dental introduces the best aligners for the best smiles.</p>
                        <div className="swiper_table">
                            <div className="st1">
                                <img src={icon1} alt=" innovation" />
                                <p className='hero-p'><span className='bold'>20+ years  </span> <br />of INNOVATION  </p>
                            </div>
                            <div className="st1">
                                <img src={icon2} alt="age groups" />
                                <p className='hero-p'><span className='bold'>CUSTOMISED  </span> <br />for age groups </p>
                            </div>
                            <div className="st1">
                                <img src={icon3} alt="lifestyle" />
                                <p className='hero-p'><span className='bold'>CONVENIENT</span> <br />Lifestyle </p>

                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className='swiper_slide swiper_slide3'>
                    <div className="bg2"></div>
                    <div className="content_box content_box2">
                        <div className="logo_div">
                            <img src={logo} alt="smile into laughters" className='mylogo' />
                        </div>
                        <div className="button_div">
                            <a href="tel:+917263802727" className='btn'><span><IoCallSharp /></span> 7263802727 </a>
                        </div>
                        <h1>BURST SMILES INTO LAUGHTERS</h1>
                        <p>Team Dental introduces the best aligners for the best smiles.</p>
                    </div>
                </SwiperSlide>


            </Swiper>
        </div>
    )
}

export default SwiperComponent